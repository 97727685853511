import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { dragAndDropIcon } from '../../../assets/base64';
import comunication from '../../../communication';
import Loader from '../../../components/loader';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Modal from '../../../components/modal';
import ErrorModal from '../../../components/errorModal';
import { ObjectKeys } from '@/types/objectKeys';
import useAgencyStore from '../../../store/agency';
import useResponsive from '../../../hooks/responsive/useResponsive';

const AddLogo: FunctionComponent = () => {
    const [logoUrl, setLogoUrl] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { isMobile } = useResponsive();
    const [loader, setLoader] = useState(false);
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);
    const [overSizeModal, setOverSizeModal] = useState<boolean>(false);
    const [renderKey, setRenderKey] = useState<number>(0);

    const appUrl = process.env.REACT_APP_URL;
    const getAgency = async (): Promise<void> => {
        setLoader(true);
        comunication.getAgency().then((res: ObjectKeys) => {
            setAgencyId(res.data.data.id);
            setLogoUrl(res.data.data.logo ? `${appUrl}/${res.data.data.logo}` : '');
            setLoader(false);
        });
    };

    const clearCacheData = async (): Promise<void> => {
        try {
            const cacheKeys = await caches.keys();
            await Promise.all(cacheKeys.map((key) => caches.delete(key)));
        } catch (error) {
            console.error('Error clearing cache:', error);
        }
    };
    const clearCacheOnReload = (): void => {
        window.addEventListener('beforeunload', clearCacheData);
    };
    useEffect(() => {
        clearCacheOnReload();
        return () => {
            window.removeEventListener('beforeunload', clearCacheData);
        };
    }, []);

    useEffect(() => {
        clearCacheData().then(() => {
            getAgency();
        });
    }, []);
    useEffect(
        () => () => {
            clearCacheData();
        },
        [],
    );

    const handleDelete = async (): Promise<void> => {
        setLoader(true);
        await comunication
            .deleteLogo(agencyId)
            .then(() => {
                comunication.getAgency().then((res: ObjectKeys) => {
                    useAgencyStore.setState({ agency: res.data.data });
                    setAgencyId(res.data.data.id);
                    setLogoUrl(res.data.data.logo ? `${appUrl}/${res.data.data.logo}` : '');
                    setRenderKey(renderKey + 1);
                    clearCacheData();
                    setLoader(false);
                });
            })
            .catch(() => {
                setLoader(false);
            });
    };

    const handleChange = async (file: [Blob]): Promise<void> => {
        if (file[0].size > 5242880) {
            setOverSizeModal(true);
        } else {
            setLoader(true);

            // Resize the image before upload
            const resizedImage = await resizeImage(file[0]);

            comunication.uploadAgencyLogo(resizedImage, agencyId).then(() => {
                comunication.getAgency().then((res: ObjectKeys) => {
                    useAgencyStore.setState({ agency: res.data.data });
                    setAgencyId(res.data.data.id);
                    setLogoUrl(res.data.data.logo ? `${appUrl}/${res.data.data.logo}` : '');
                    setRenderKey(renderKey + 1);
                    clearCacheData();
                    setLoader(false);
                });
            });
        }
    };

    const resizeImage = (file: Blob, maxWidth = 200, maxHeight = 300): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Calculate the new dimensions
                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth || height > maxHeight) {
                        if (width > height) {
                            height = (maxWidth / width) * height;
                            width = maxWidth;
                        } else {
                            width = (maxHeight / height) * width;
                            height = maxHeight;
                        }
                    }

                    // Set canvas size and draw the image
                    canvas.width = width;
                    canvas.height = height;
                    ctx?.drawImage(img, 0, 0, width, height);

                    // Convert the canvas to a Blob
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Canvas to Blob conversion failed'));
                            }
                        },
                        file.type, // Preserve original file type
                        0.8, // Set image quality (optional, for formats like JPEG)
                    );
                };
                img.onerror = (error) => reject(error);
                img.src = event?.target?.result as string;
            };

            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    return (
        <Content className={isMobile ? 'mobile' : 'desktop'}>
            {loader && <Loader />}
            {wrongTypeModal && (
                <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                    <ErrorModal
                        t={t}
                        errorMessage={t('pages.agency.addLogo.wrongType').text}
                        setOpenModal={setWrongTypeModal}
                    />
                </Modal>
            )}
            {overSizeModal && (
                <Modal modalVisible={overSizeModal} closeModal={() => setOverSizeModal(!overSizeModal)}>
                    <ErrorModal t={t} errorMessage={t('pages.error.overSize').text} setOpenModal={setOverSizeModal} />
                </Modal>
            )}
            <div className="content-wrapper">
                <div className="preview-upload">
                    {logoUrl !== '' && (
                        <div className="preview">
                            <h4>{t('pages.agency.addLogo.currentLogo').text}</h4>
                            <img
                                className={isMobile ? 'mobile' : ''}
                                key={renderKey}
                                src={`${logoUrl}?renderKey=${renderKey}`}
                            />
                            <Icon>
                                <FontAwesomeIcon
                                    onClick={() => handleDelete()}
                                    icon={faTrashCan}
                                    style={{ color: 'var(--danger)' }}
                                    width={'33px'}
                                    height={'22px'}
                                />
                            </Icon>
                        </div>
                    )}
                    <div className={isMobile ? 'upload mobile' : 'upload'}>
                        <h4>{t('pages.agency.addLogo.changeLogo').text}</h4>
                        <FileUploader
                            multiple={true}
                            handleChange={handleChange}
                            name="files"
                            types={['jpg', 'jpeg', 'png']}
                            onTypeError={() => {
                                setWrongTypeModal(true);
                            }}
                        >
                            <div className={isMobile ? 'mobile dropzone' : 'dropzone'}>
                                <p>{t('pages.agency.addLogo.addLogo').text}</p>
                                <img src={dragAndDropIcon} />
                            </div>
                        </FileUploader>
                    </div>
                </div>

                <div className="example">
                    <img src={require('../../../assets/invoice-preview.png')} />
                </div>
            </div>
        </Content>
    );
};

const Content = styled.div`
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .content-wrapper {
        display: flex;
        h4 {
            font-size: 14px;
            text-transform: uppercase;
            color: var(--black);
            margin-bottom: 15px;
        }
        .preview-upload {
            width: 56%;
        }

        .preview {
            img {
                &.mobile {
                    width: 150px;
                }
                width: 200px;
                height: auto;
                display: inline-block;
            }
            margin-bottom: 20px;
        }
        .upload {
            label {
                display: flex;
                width: 165px;
                &.mobile {
                    width: 150px;
                }
            }
        }
        .dropzone {
            cursor: pointer;
            position: relative;
            width: 165px;
            min-height: 120px;
            border: 3px dashed #e7e7e7;
            border-radius: 10px;
            padding-top: 5px;
            padding-bottom: 0;
            color: #92a5ac;
            text-align: center;
            &.mobile {
                width: 150px;
            }
            p {
                margin-top: 10px !important;
                border-top: none;
                font-size: 12px;
            }
            img {
                position: absolute;
                width: 100%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 40px;
            }
        }
        .example {
            max-width: 43%;
            float: right;
            img {
                width: 100%;
            }
        }
    }
`;

const Icon = styled.div`
    width: 33px;
`;

export default AddLogo;
