import { useQuery } from '@tanstack/react-query';
import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { queryClient } from '../App';

const fetchMemos = async () => {
    try {
        const response = await communication.getAllMemos();
        if (isStatusSuccess(response.status)) {
            return response.data.data;
        } else {
            throw new Error('Failed to fetch memos');
        }
    } catch (error) {
        console.error(error);
    }
};

export const useMemosQuery = () => {
    const invalidate = (): Promise<void> => {
        return queryClient.invalidateQueries({ queryKey: ['memos'] });
    };

    const remove = (): void => {
        return queryClient.removeQueries({ queryKey: ['memos'] });
    };

    const {
        data: memos,
        refetch,
        isLoading,
        isFetching,
        isRefetching,
        error,
    } = useQuery({
        queryKey: ['memos'],
        queryFn: () => fetchMemos(),
        staleTime: 1000 * 60 * 10, // 10 minutes
    });

    return { memos, refetch, isLoading, isFetching, error, invalidate, isRefetching, remove };
};
