import { FunctionComponent, useState, useEffect, SetStateAction, Dispatch, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '../../types/objectKeys';
import { formatClientParams } from '../../functions/formatClientParams';
import communication from '../../communication';
import colors from '../../global/colors';
import Search from '../input/search';
import Button, { ButtonVariant } from '../button';
import { getNumberByClientType, getNumberNameByClientType } from '../clientCard/getByClientType';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
interface Props {
    t: Function;
    setAddClientModal: Function;
    agency: ObjectKeys;
    setClients: Dispatch<SetStateAction<ObjectKeys>>;
    clients: ObjectKeys;
    handleSelectedClient: Function;
    deleteClientModal: boolean;
    editClientModal: boolean;
    addClientModal: boolean;
    setClickedClient: Dispatch<SetStateAction<string>>;
    setEditClientModal: Dispatch<SetStateAction<boolean>>;
    setDeleteClientModal: Dispatch<SetStateAction<boolean>>;
    invoice?: ObjectKeys;
    userId?: string;
    type?: string;
    loading?: Function;
}
const InvoiceClients: FunctionComponent<Props> = ({
    t,
    setAddClientModal,
    agency,
    setClients,
    clients,
    type,
    handleSelectedClient,
    deleteClientModal,
    editClientModal,
    addClientModal,
    setClickedClient,
    setEditClientModal,
    setDeleteClientModal,
    invoice,
    userId,
    loading,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [clientsList, setClientsList] = useState<ObjectKeys>({});
    const [params] = useState({
        limit: '2000',
        page: 1,
        search: {},
        searchJoin: 'and',
    });

    const { pathname } = useLocation();

    const invoiceType =
        type !== ''
            ? type
            : pathname.includes('edit')
            ? invoice?.type === 'national'
                ? 'domestic'
                : 'foreign'
            : pathname.includes('domestic')
            ? 'domestic'
            : 'foreign';

    const formattedParams = useMemo(() => formatClientParams(params.search), [params]);

    const fetchClients = (): void => {
        loading && loading(true);
        communication
            .getClients(
                userId ? userId : agency?.user_id,
                userId
                    ? {}
                    : {
                          ...params,
                          search: formattedParams
                              ? `is_foreign:${invoiceType === 'domestic' ? '0' : '1'};` + formattedParams
                              : `is_foreign:${invoiceType === 'domestic' ? '0' : '1'}`,
                      },
            )
            .then((res: ObjectKeys) => {
                setClients(res.data);
                setClientsList(res.data);
                loading && loading(false);
            });
    };

    const getData = useCallback(() => {
        if (invoiceType) {
            fetchClients();
        }
    }, [deleteClientModal, editClientModal, addClientModal, invoice, formattedParams]);

    useEffect(() => {
        if (invoiceType !== '') {
            fetchClients();
        }
    }, [invoiceType]);

    useEffect(() => {
        //Get all clients by user id
        if (searchValue !== '') {
            const filteredClients = clientsList?.data?.filter((client: ObjectKeys) => {
                return client.company_name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
            });

            if (filteredClients.length > 0) {
                setClients({ data: filteredClients });
            } else {
                // If there are no matching items, reset to the original list
                setClients({ data: [] });
            }
        } else {
            // If searchValue is empty, get all clients
            getData();
        }
    }, [searchValue]);

    return (
        <ClientsWrapper>
            <TitleBtn>
                <h2> {t('pages.editInvoice.clients').text}</h2>
                <Button
                    variant={ButtonVariant.link}
                    color={colors.purple}
                    icon={true}
                    onClick={() => setAddClientModal(true)}
                >
                    <FontAwesomeIcon
                        className="icon"
                        color={colors.purple}
                        icon={faPlus}
                        style={{ marginRight: '5px' }}
                    />
                    {t('pages.editInvoice.newClient').text}
                </Button>
            </TitleBtn>
            <Search
                value={searchValue}
                onChange={(value: string) => {
                    setSearchValue(value);
                }}
                placeholder={t('pages.invoices.filters.search').text}
            />
            {clients && invoice && (
                <ClientsList className="client-list">
                    {clients?.data?.map((client: ObjectKeys) => {
                        // Check if the client have the same data as on the invoice
                        const isSelectedClient =
                            invoice?.client?.data?.id === client.id &&
                            invoice?.client?.data.company_name === client.company_name &&
                            invoice?.client?.data.currency === client.currency &&
                            invoice?.client?.data.is_foreign === client.is_foreign &&
                            invoice?.client?.data.pib === client.pib &&
                            invoice?.client?.data.vat === client.vat &&
                            invoice?.client?.data.address === client.address &&
                            invoice?.client?.data.city === client.city &&
                            invoice?.client?.data.country === client.country &&
                            invoice?.client?.data.phone === client.phone &&
                            invoice?.client?.data.postal_code === client.postal_code &&
                            invoice?.client?.data.type === client.type;
                        return (
                            <Client
                                key={client?.id}
                                onClick={() => {
                                    handleSelectedClient(client);
                                }}
                                onTouchEnd={() => {
                                    // e.preventDefault();
                                    handleSelectedClient(client);
                                }}
                            >
                                <div className={`cursor-pointer ${isSelectedClient ? 'selected' : ''}`}>
                                    <div>
                                        <div>{client?.company_name}</div>
                                        <div>
                                            {client?.is_foreign
                                                ? t('pages.clientCard.infoWrapper.taxNumber').text
                                                : t(`pages.clientCard.infoWrapper.${getNumberNameByClientType(client)}`)
                                                      .text}

                                            {client?.is_foreign ? client.pib : getNumberByClientType(client)}
                                        </div>
                                    </div>
                                    <div className="icons">
                                        <FontAwesomeIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setClickedClient(client.id);
                                                setEditClientModal(true);
                                            }}
                                            icon={faEdit}
                                        />
                                        <FontAwesomeIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setClickedClient(client.id);
                                                setDeleteClientModal(true);
                                            }}
                                            icon={faTrashCan}
                                        />
                                    </div>
                                </div>
                            </Client>
                        );
                    })}
                </ClientsList>
            )}
        </ClientsWrapper>
    );
};

const ClientsWrapper = styled.div`
    margin-top: 30px;
    h2 {
        font-size: 24px;
        font-weight: 400;
    }
`;
const Client = styled.div`
    padding-right: 20px;
    color: var(--gray);

    > div {
        display: flex;
        border-bottom: 1px solid var(--border-color);
        padding: 12px 18px 11px;
        justify-content: space-between;
        &:hover {
            text-decoration: none;
            background: var(--white);
            border-radius: 5px;
            border-bottom: 1px solid transparent;
            box-shadow: 0 0 3px 3px var(--border-color);
            .icons {
                opacity: 1;
            }
        }
    }
    .selected {
        border-left: 3px solid var(--purple);
        border-bottom: 1px solid transparent;
        box-shadow: 0 0 3px 3px var(--light-gray);
        border-radius: 5px;
        text-decoration: none;
        background: var(--white);
    }
    .icons {
        opacity: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 35%;
        svg {
            cursor: pointer;
            :nth-of-type(2) {
                color: var(--danger);
                margin-left: 20px;
            }
        }
    }
`;
const TitleBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const ClientsList = styled.div`
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: auto;
    max-height: 233px;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        grid-template-columns: 1fr;
    }
`;
export default InvoiceClients;
