import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';
import downloadInvoice from './downloadInvoice';
export default class Invoices {
    public getInvoice(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoices.invoice(id),
        });
    }
    public getInvoices(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoices.myInvoices,
            params: params,
        });
    }
    public getInvoiceStatistics(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoices.statistics,
        });
    }
    public deleteInvoice(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            url: routes.invoice.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public copyInvoice(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.invoice.copy(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public cancelInvoice(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.invoice.cancel(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public editInvoice(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoice.byId(id),
            data: options,
        });
    }
    public paymentInvoice(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoice.payment(id),
            data: options,
        });
    }

    public eInvoice(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.invoice.eInvoice,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public newInvoice(options: ObjectKeys, type: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoice.new(type),
            data: options,
        });
    }
    public sendInvoice(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.invoice.sendInvoice(id),
            data: options,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': getCurrentLanguage(),
            },
        });
    }
    public sendIos(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.invoice.sendIos,
            data: options,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }

    public downloadInvoice(
        id: string | any,
        token: string,
        invoiceNo: string,
        foreign: boolean,
        proforma?: boolean,
        qr_code?: string,
    ): Promise<any> {
        return downloadInvoice(id, token, invoiceNo, foreign, proforma, qr_code)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                throw error; // Rethrow the error to propagate it to the caller
            });
    }

    public refreshEInvoice(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.invoice.refreshEInvoice,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public getAdvancesForInvoice(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoices.myInvoices,
            params: params,
        });
    }
    public deleteInvoiceSpecificPayment(invoiceId: string, paymentId: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoice.specificPayment(invoiceId, paymentId),
        });
    }
    public updateInvoiceSpecificPayment(invoiceId: string, paymentId: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoice.specificPayment(invoiceId, paymentId),
            data: options,
        });
    }
    public prepaidInvoiceCheck(invoiceId: string): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.invoice.prepaidCheck,
            data: { id: invoiceId },
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public removeAllPayments(invoiceId: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoice.removeAllPayments(invoiceId),
        });
    }
    public getInvoiceNumberAndPrefix(year: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoices.getInvoiceNumber(year),
        });
    }
    public importInvoices(user_id: string, sendData: any): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.invoices.importInvoicesFromExcel,
            data: {
                user_id: user_id,
                file: sendData,
            },
        });
    }
}
