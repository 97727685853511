import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Success from '../../../../components/successModal';
import Modal from '../../../../components/modal';
import useUserStore from '../../../../store/user';
import useAgencyStore from '../../../../store/agency';
import useProfileStore from '../../../../store/profile';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import { Comments, Row } from '../../../pausal/invoices/invoiceEdit/style';
import TextArea from '../../../../components/input/textarea';
import { breakpoints } from '../../../../constants/breakpoints';

const DefaultNote: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { isMobile } = useResponsive();

    const { agency } = useAgencyStore();
    const userId = agency.user.data.id;

    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [formData, setFormData] = useState({
        note_domestic: '',
        note_foreign: '',
    });

    const handleSave = async (): Promise<void> => {
        setLoaderVisible(true);
        const res = await communication.updateUser(formData, userId);
        if (res.status === 200) {
            setFormData({
                note_domestic: res.data.data.note_domestic,
                note_foreign: res.data.data.note_foreign,
            });
            useUserStore.setState({ userInfo: res?.data?.data });
            useProfileStore.setState({ profile: res.data.data });
            useAgencyStore.setState((prevState) => ({
                agency: {
                    ...prevState.agency,
                    user: {
                        ...prevState.agency.user,
                        data: res.data.data,
                    },
                },
            }));
            setLoaderVisible(false);
            setSuccessfullySaved(true);
        }
        setLoaderVisible(false);
    };

    useEffect(() => {
        if (agency?.user?.data) {
            const { note_domestic, note_foreign } = agency.user.data;
            setFormData({
                note_domestic: note_domestic ?? 'Rok za plaćanje je 15 dana',
                note_foreign: note_foreign ?? 'Payment deadline is 15 days',
            });
        }
    }, [agency]);

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <Container className={isMobile ? 'mobile-width' : 'desktop'}>
                <Content>
                    <div className="note">
                        <p>{t('pages.editInvoice.previewModal.invoiceNoteHeaderDomestic').text}</p>
                    </div>
                    <div className="box">
                        <Row className="row-test">
                            <Comments className="comments-row">
                                <p>{t('pages.editInvoice.previewModal.comment').text}</p>
                                <TextArea
                                    value={formData.note_domestic}
                                    onChange={(value: string) => {
                                        setFormData({ ...formData, note_domestic: value });
                                    }}
                                    placeholder={
                                        t('pages.editInvoice.previewModal.defaultNotePlaceholderDomestic').text
                                    }
                                    maxLength={250}
                                    height={'100%'}
                                    dataCy={'note_domestic'}
                                />
                            </Comments>
                            <Comments className="comments-row-nonEditable">
                                <p style={{ marginBottom: '20px' }}>Faktura je važeća bez pečata i potpisa</p>
                                <p style={{ marginBottom: '20px', wordBreak: 'break-all' }}>
                                    Identifikacioni broj:
                                    2bbc9bda00029fe0693a9aqi90bf7e5ciaa72zjhy0078de1d9c36211bf0dd0gw2w
                                </p>
                                <p>Napomena o poreskom oslobođenju.</p>
                                <p>Poreski obveznik nije u sistemu PDV-a.</p>
                                <p>
                                    PDV nije obračunat na fakturi u skladu sa članom 33. Zakona o porezu na dodatu
                                    vrednost.
                                </p>
                                <p>Mesto izdavanja: Beograd, 11000</p>
                            </Comments>
                        </Row>
                    </div>
                    <div className="note">
                        <p>{t('pages.editInvoice.previewModal.invoiceNoteHeaderForeign').text}</p>
                    </div>
                    <div className="box">
                        <Row className="row-test">
                            <Comments className="comments-row">
                                <p>Note</p>
                                <TextArea
                                    value={formData.note_foreign}
                                    onChange={(value: string) => {
                                        setFormData({ ...formData, note_foreign: value });
                                    }}
                                    placeholder={t('pages.editInvoice.previewModal.defaultNotePlaceholderForeign').text}
                                    maxLength={250}
                                    height={'100%'}
                                    dataCy={'note_foreign'}
                                />
                            </Comments>
                            <Comments className="comments-row-nonEditable">
                                <p style={{ marginBottom: '20px' }}>Invoice is valid without stamp and signature</p>
                                <p style={{ marginBottom: '20px', wordBreak: 'break-all' }}>
                                    Identification number:
                                    2bbc9bda00029fe0693a9aqi90bf7e5ciaa72zjhy0078de1d9c36211bf0dd0gw2w
                                </p>
                                <p>Note on tax exemption:</p>
                                <p>Not in the VAT system.</p>
                                <p>
                                    VAT not calculated on the invoice according to article 33 of Law on value added tax.
                                </p>
                                <p>Place: Belgrade, 11000</p>
                            </Comments>
                        </Row>
                    </div>
                </Content>
                <div className={'button-container'}>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        size={'200px'}
                        height={'68px'}
                        onClick={() => handleSave()}
                        dataCy={'save-button'}
                    >
                        {t('pages.agency.eInvoices.button').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

const Container = styled.div`
    vertical-align: top;
    &.desktop {
        width: 100%;
    }
    &.mobile {
        width: 100%;
    }
    .button-container {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 1023px) {
        .button-container {
            margin-top: 20px;
        }
    }
`;

const Content = styled.div`
    width: 100%;
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    text-align: center;
    display: inline-block;
    vertical-align: top;

    .note {
        margin: 20px 0;
        text-align: left;
    }

    .note p {
        font-size: 15px;
    }

    .box {
        display: flex;
        gap: 10px;
        width: 100%;
        height: 100%;

        .row-test {
            width: 100%;
        }

        .comments-row input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid black;
            width: 100%;
            outline: none;
            padding-bottom: 10px;
            padding-top: 65px;
            font-family: inherit;
            font-size: 14px;
            background-color: transparent;
        }

        .comments-row input::placeholder {
            font-size: 14px;
            font-weight: 600;
        }

        .comments-row textarea {
            @media screen and (max-width: ${breakpoints.laptop - 1}px) {
                min-height: 200px;
            }
        }

        .comments-row,
        .comments-row-nonEditable {
            width: 100%;
            height: 100%;
            border: 0.5px gray solid;
            margin-bottom: 5px;
            padding: 10px;
        }

        .comments-row-nonEditable {
            margin-left: 5px;
            display: flex;
            flex-direction: column;
            text-align: left;
        }

        .comments-row p {
            text-align: left;
            margin-bottom: 5px;
            padding-left: 5px;
        }

        .comments-row div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media screen and (max-width: 1023px) {
        .box {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            margin-bottom: 10px;

            .inputEditable {
                width: 50%;
            }

            .row-test {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 3px;
            }

            .comments-row,
            .comments-row-nonEditable {
                width: 100%;
                border: 0.5px gray solid;
                margin-bottom: 5px;
                padding: 10px;
            }

            .comments-row p {
                padding-left: 0;
            }

            .comments-row input {
                min-height: 100px;
                padding-top: 30px;
                margin-bottom: 70px;
            }

            .comments-row-nonEditable {
                margin-left: 0;
            }

            .comments-row div {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
`;

export default DefaultNote;
