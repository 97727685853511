import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import useGeneralStore from '../../../../store/general';
import usePaymentValueValidation from '../../../../hooks/usePaymentValueValidation';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import moment from 'moment';
import getAllInvoices from '../getAllInvoices';
import getAllEinvoices from '../../einvoices/getAllInvoices';

type Props = {
    t: Function;
    setOpenPaymentModal: Dispatch<SetStateAction<boolean>>;
    clickedInvoice: string;
    invoicePaidValue: any;
    success?: Function;
    setLoaderVisible?: Dispatch<SetStateAction<boolean>>;
    setStatistics?: Dispatch<SetStateAction<ObjectKeys>>;
    currency?: string;
    type: 'invoice' | 'einvoice';
};

const PaymentModal: FunctionComponent<Props> = ({
    t,
    setOpenPaymentModal,
    clickedInvoice,
    invoicePaidValue,
    success,
    setLoaderVisible,
    currency,
    type,
}) => {
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paidNumber, setPaidNumber] = useState(invoicePaidValue);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const isPaidValid = usePaymentValueValidation(paidNumber, invoicePaidValue, t);

    const handlePayment = (invoiceId: string): void => {
        if (isPaidValid !== '') {
            setShowValidationMessage(true);
            return;
        }
        setOpenPaymentModal(false);
        setLoaderVisible && setLoaderVisible(true);
        setShowValidationMessage(false);
        communication
            .paymentInvoice(invoiceId, {
                paid_date: moment(paymentDate.toString()).format('YYYY-MM-DD hh:mm:ss'),
                value: paidNumber?.replace(/\./g, '')?.replace(',', '.'),
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setOpenPaymentModal(false);
                    success && success();
                    return;
                }
            })
            .then(() => {
                if (type === 'invoice') {
                    getAllInvoices().then(() => {
                        setLoaderVisible && setLoaderVisible(false);
                    });
                } else {
                    getAllEinvoices().then(() => {
                        setLoaderVisible && setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible && setLoaderVisible(false);
                    setOpenPaymentModal(false);
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            });
    };

    return (
        <PaymentModalWrapper>
            <ModalContent>
                <h4>{t('pages.invoices.paymentModal.title').text}</h4>
                <DateWrapper>
                    <Input
                        type={InputComponentType.Date}
                        label={t('pages.invoices.paymentModal.paymentDate').text}
                        date={paymentDate}
                        // validation={paidDate !== null ? isPaidDateValid : ''}
                        onChange={(value: any) => {
                            setPaymentDate(value);
                        }}
                    />
                </DateWrapper>
                <div className="input-pay-wraper">
                    <div className="full-width">
                        <Input
                            type={InputComponentType.Amount}
                            label={t('pages.invoices.paymentModal.paid').text}
                            value={paidNumber}
                            validation={showValidationMessage && isPaidValid ? isPaidValid : ''}
                            onChange={(value: string) => setPaidNumber(value)}
                        />
                    </div>
                    <p>{`(${currency})`}</p>
                </div>
            </ModalContent>

            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handlePayment(clickedInvoice);
                    }}
                    size={'100%'}
                >
                    {t('pages.invoices.paymentModal.confirmPayment').text}
                </Button>
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    onClick={() => setOpenPaymentModal(false)}
                    size={'100%'}
                >
                    {t('pages.invoices.paymentModal.close').text}
                </Button>
            </ButtonContainer>
        </PaymentModalWrapper>
    );
};

const PaymentModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
    z-index: 9999;
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--light-gray);
    }
`;
const ModalContent = styled.div`
    padding: 35px 50px;

    h4 {
        margin-bottom: 30px;
        text-align: center;
    }
    .input {
        text-align: left;
    }
    .input-pay-wraper {
        display: flex;
        align-items: flex-end;
        .full-width {
            width: 100%;
        }
        p {
            margin-bottom: 10px;
        }
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
`;
export default PaymentModal;
