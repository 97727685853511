import { queryClient } from '../App';
import communication from '../communication';
import { ObjectKeys } from '../types/objectKeys';
import { faCog, faLifeRing, faPaste, faFile, faRightToBracket, faBook } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useTranslations from '../hooks/useTranslation';
import useLanguageStore from '../store/language';
import { useNavigate } from 'react-router-dom';

export const useUserDropdownList = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const isAdmin = localStorage.getItem('role') === 'admin' ? true : false;

    const logoutHandler = async (): Promise<void> => {
        await communication
            .logout()
            .then((res: ObjectKeys) => {
                if (res) {
                    navigate('/login');
                }
            })
            .catch((error: any) => {
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
        queryClient.removeQueries();
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('role');
        sessionStorage.setItem('isAuthenticated', 'false');
        sessionStorage.setItem('isNotfAuthenticated', 'false');
    };

    const userDropdown = [
        {
            icon: faCog,
            label: t('navbar.main.settings').text,
            link: '/settings/notifications',
            newTab: false,
        },
        {
            icon: faLifeRing,
            label: t('navbar.main.help').text,
            link: 'https://pausal.rs/pausal-app/pitanja/',
            newTab: true,
        },
        {
            icon: faPaste,
            label: t('navbar.main.privacy').text,
            link: '/Privacy.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.paymentTerms').text,
            link: '/Uslovi_plaćanja_PAUSAL.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.terms').text,
            link: '/USLOVI_KORIŠĆENJA_Clever.pdf',
            newTab: true,
        },
        { icon: faRightToBracket, label: t('navbar.main.logOut').text, handler: logoutHandler },
    ];

    const adminDropdown = [
        {
            icon: faLifeRing,
            label: t('navbar.main.help').text,
            link: 'https://pausal.rs/pausal-app/pitanja/',
            newTab: true,
        },
        {
            icon: faPaste,
            label: t('navbar.main.privacy').text,
            link: '/Privacy.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.paymentTerms').text,
            link: '/Uslovi_plaćanja_PAUSAL.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.terms').text,
            link: '/USLOVI_KORIŠĆENJA_Clever.pdf',
            newTab: true,
        },
        {
            icon: faBook,
            label: 'Storybook',
            link: '/storybook/index.html',
            newTab: true,
        },
        { icon: faRightToBracket, label: t('navbar.main.logOut').text, handler: logoutHandler },
    ];
    if (isAdmin) {
        return adminDropdown;
    } else {
        return userDropdown;
    }
};
