import { formatNumber } from '../../../functions/format';
import { ObjectKeys } from '../../../types/objectKeys';

export default function countTotal(services: ObjectKeys[]): any {
    const res = formatNumber(
        services?.reduce((accumulator: number, object: ObjectKeys) => {
            return accumulator + (isNaN(Number(object?.pivot?.total_price)) ? 0 : Number(object?.pivot?.total_price));
        }, 0),
        2,
        3,
        '.',
        ',',
    );
    if (typeof res === 'string') return res.replaceAll('.', '').replace(',', '.');
    else return 0;
}

export function calculatePrepaidInvoiceTotal(payments: ObjectKeys): number {
    if (!payments?.data || !Array.isArray(payments.data)) {
        return 0;
    }

    return payments.data
        .filter((payment) => payment.prepaid_invoice_label)
        .reduce((total, payment) => {
            const value = parseFloat(payment.value) || 0;
            return total + value;
        }, 0);
}

export function countTotalProforma(services: ObjectKeys[]): any {
    const res = formatNumber(
        services?.reduce((accumulator: number, object: ObjectKeys) => {
            return (
                accumulator +
                (isNaN(object?.price_per_unit) ? 0 : Number(object?.price_per_unit) * Number(object?.quantity))
            );
        }, 0),
        2,
        3,
        '.',
        ',',
    );
    if (typeof res === 'string') return res.replaceAll('.', '').replace(',', '.');
    else return 0;
}
