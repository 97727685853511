import useTranslations from '../hooks/useTranslation';
import useLanguageStore from '../store/language';

const useEmailsValidation = (email: string, emailsArray: Array<string>, required: boolean): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    // If email is required and both email and emailsArray are empty
    if (required && emailsArray.length === 0 && email === '') {
        return t('pages.invoices.sendModal.enterEmail').text;
    }

    // If an email is provided, check for duplicates
    if (email && emailsArray.includes(email)) {
        return t('pages.invoices.sendModal.sameEmail').text;
    }

    // Validate email format if something is entered
    if (email) {
        const isEmailValid =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                String(email).toLowerCase(),
            );
        if (!isEmailValid) {
            return t('pages.validationMessages.emailInvalid').text;
        }
    }

    // No error
    return '';
};

export default useEmailsValidation;
