import { useEffect, useState } from 'react';
import styled from 'styled-components';
import communication from '../../../communication';
import { ObjectKeys } from '@/types/objectKeys';

import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';

import { formatTwoDecimals } from '../../../functions/format';
import visaLogo from '../../../assets/cards/Visa50.gif';
import dinaLogo from '../../../assets/cards/dinacard50.png';
import masterLogo from '../../../assets/cards/MasterCard50.gif';
import maestroLogo from '../../../assets/cards/maestro50.gif';
import masterIdCheckLogo from '../../../assets/cards/masterIdCheck.png';
import visaSecureLogo from '../../../assets/cards/visa-secure.png';
import dinaMobile from '../../../assets/cards/mobile/dinacard23.png';
import masterMobile from '../../../assets/cards/mobile/MasterCard23.png';
import maestroMobile from '../../../assets/cards/mobile/maestro23.png';
import otpLogo from '../../../assets/cards/otpLogo.png';
import visaMobile from '../../../assets/cards/mobile/Visa23.png';

import useEmailValidation from '../../../hooks/useEmailValidation';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import { useNavigate } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import useServicesCartStore from '../../../store/servicesCart';

import Loader from '../../../components/loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from 'react-google-recaptcha';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useResponsive from '../../../hooks/responsive/useResponsive';

interface StepFourProps {
    cart: ObjectKeys;
    previousStep: Function;
}

const StepFour: React.FC<StepFourProps> = ({ cart, previousStep }) => {
    const { currentLang } = useLanguageStore();
    const returnUrl = process.env.REACT_APP_RETURN_URL;

    const isDevEnviroment = process.env.REACT_APP_RETURN_URL === 'https://dev.pausal.rs' ? true : false;

    const wspayFormUrl = process.env.REACT_APP_WSPAY_FORM_URL;
    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const navigate = useNavigate();
    const defaultLang = lang === 'Srpski' ? 'sr' : lang === 'English' ? 'en' : lang === 'Русский' ? 'ru' : 'sr';

    const [loader, setLoader] = useState<boolean>(false);
    const [isChecked, setChecked] = useState(false);

    const { isMobile } = useResponsive();

    const [recaptchaToken, setRecaptchaToken] = useState<string>('');

    const [errorMessageTerms, setErrorMessageTerms] = useState<boolean>(false);
    const [errorMessageNotRobot, setErrorMessageNotRobot] = useState<boolean>(false);

    const [discount, setDiscount] = useState<number>(0);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [pib, setPib] = useState<string>('');

    const [validationError, setValidationError] = useState(false);

    const handleRecaptchaChange = (token: string | null): void => {
        if (token) {
            setRecaptchaToken(token);
        } else {
            setRecaptchaToken('');
        }
    };

    useEffect(() => {
        if (cart) {
            setFirstName(cart.first_name);
            setLastName(cart.last_name);
            setEmail(cart.email);
            setPib(cart.pib);
        }
    }, [cart]);

    const addCartIdToURL = (id: string): Promise<boolean> => {
        return new Promise((resolve) => {
            const newUrl = `/guest/3/${id}`;
            navigate(newUrl);
            resolve(true);
        });
    };

    const initiateWsPay = (): void => {
        const newCart = {
            ...cart,
            email: email,
            first_name: firstName,
            last_name: lastName,
            pib: pib,
            total_price: cart.total_price - discount,
            items: cart.items.map((item: ObjectKeys) => {
                delete item.name_en;
                delete item.name_rs;
                delete item.name_ru;
                delete item.description_en;
                delete item.description_rs;
                delete item.description_ru;
                return item;
            }),
        };

        setLoader(true);
        try {
            useServicesCartStore.setState({ cartData: {} });
            communication
                .createGuestCart(newCart)
                .then((res: ObjectKeys) => {
                    const amount = newCart.total_price;
                    const formattedAmount = amount.toFixed(2).replace('.', ',');
                    const cart_id = res.data.data.id;
                    addCartIdToURL(cart_id).then((added: boolean) => {
                        if (!added) {
                            setLoader(false);
                            return;
                        } else {
                            communication.getGuestSignature(formattedAmount, cart_id).then((response: ObjectKeys) => {
                                const signature = response.data.signature;
                                const shop_id = response.data.shop_id;
                                const payForm = document.querySelector('form[name="pay"]') as HTMLFormElement;
                                if (payForm && signature) {
                                    const signatureInput = payForm.querySelector('input[name="Signature"]');
                                    const shopIdInput = payForm.querySelector('input[name="ShopID"]');
                                    const shoppingCartIdInput = payForm.querySelector('input[name="ShoppingCartID"]');
                                    const totalAmountInput = payForm.querySelector('input[name="TotalAmount"]');
                                    if (
                                        signatureInput instanceof HTMLInputElement &&
                                        shopIdInput instanceof HTMLInputElement &&
                                        shoppingCartIdInput instanceof HTMLInputElement &&
                                        totalAmountInput instanceof HTMLInputElement
                                    ) {
                                        signatureInput.value = signature;
                                        shopIdInput.value = shop_id;
                                        shoppingCartIdInput.value = cart_id;
                                        totalAmountInput.value = formatTwoDecimals(formattedAmount.toString());
                                        payForm.submit();
                                    } else {
                                        console.error('Signature input element not found or not an HTMLInputElement');
                                    }
                                }
                            });
                        }
                    });
                })
                .catch((error: any) => {
                    console.error(error);
                    setLoader(false);
                    toast.error(t('suggestion.error').text);
                });
        } catch (error) {
            console.error('Error:', error);
            setLoader(false);
            toast.error(t('suggestion.error').text);
        }
    };

    const openInNewTab = (link: string): void => {
        const baseUrl = window.location.origin;
        const url = new URL(link, baseUrl);
        window.open(url.href, '_blank');
    };

    const vatValidation = (vat: string | undefined): string => {
        if (!vat || vat.length === 9) {
            return '';
        }
        return t('pages.validationMessages.vatNoLength').text;
    };

    const isEmailValid = useEmailValidation(email);
    const isFirstNameValid = useCharLengthValidation(firstName, 3, true);
    const isLastNameValid = useCharLengthValidation(lastName, 3, true);
    const isPibValid = vatValidation(pib);

    return (
        <>
            {loader && <Loader />}
            <ToastContainer />
            <Title>
                <h2>{t('pages.agency.subscription.complete_order').text}</h2>
            </Title>
            <ContentThirdStep>
                <div className="leftMain">
                    <div className="leftPage">
                        <div className="inputFields">
                            <div>
                                <label>{t('pages.agency.personalInfo.name').text}</label>
                                <input
                                    type="text"
                                    maxLength={50}
                                    className="inputText"
                                    defaultValue={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                />
                                {validationError && (
                                    <p className="error2">{isFirstNameValid ? isFirstNameValid : ''}</p>
                                )}
                            </div>
                            <div>
                                <label>{t('pages.agency.personalInfo.lastName').text}</label>
                                <input
                                    type="text"
                                    maxLength={50}
                                    className="inputText"
                                    defaultValue={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                />
                                {validationError && <p className="error2">{isLastNameValid ? isLastNameValid : ''}</p>}
                            </div>
                            <div>
                                <label>{t('pages.agency.personalInfo.email').text}</label>
                                <input
                                    type="text"
                                    maxLength={50}
                                    className="inputText"
                                    defaultValue={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                                {validationError && <p className="error2">{isEmailValid ? isEmailValid : ''}</p>}
                            </div>
                            <div>
                                <label>{t('pages.agency.subscription.pib').text}</label>
                                <input
                                    type="text"
                                    maxLength={9}
                                    inputMode="numeric"
                                    pattern="\d*" // Allow only numbers
                                    className="inputText"
                                    value={pib}
                                    onChange={(e) => {
                                        const value: string = e.target.value.replace(/\D/g, '').slice(0, 9);
                                        setPib(value);
                                    }}
                                />
                                {validationError && <p className="error2">{isPibValid ? isPibValid : ''}</p>}
                            </div>
                        </div>
                    </div>
                    {!isMobile && (
                        <StepControlButton
                            step={'previous'}
                            handleClick={() => {
                                previousStep();
                                navigate('/guest/2');
                            }}
                        />
                    )}
                </div>
                <div className="rightPage">
                    <div className="table">
                        <div>
                            <p>{t('pages.agency.subscription.price').text}</p>
                            <p>{formatTwoDecimals(cart.total_price.toString())} RSD</p>
                        </div>
                        <div>
                            <p>{t('pages.agency.subscription.discount').text}</p>
                            <p>{formatTwoDecimals(discount.toString())} RSD</p>
                        </div>
                    </div>
                    <div className="total">
                        <p>{t('pages.agency.subscription.total').text}</p>
                        <p>{formatTwoDecimals((cart.total_price - discount).toString())} RSD</p>
                    </div>
                    <Row>
                        <div className="checkbox">
                            <FontAwesomeIcon
                                icon={isChecked ? faCheckSquare : faSquare}
                                className={`check-icon ${isChecked ? 'checked' : ''}`}
                                onClick={() => setChecked(!isChecked)}
                            />
                            <div className="terms">
                                <span>{t('pages.agency.subscription.introduced').text}</span>
                                <span onClick={() => openInNewTab('Privacy.pdf')} className="link">
                                    {t('pages.agency.subscription.privacy').text},
                                </span>
                                <span onClick={() => openInNewTab('USLOVI_KORIŠĆENJA_Clever.pdf')} className="link">
                                    {t('pages.agency.subscription.terms').text}
                                </span>
                                <span>{t('pages.agency.subscription.introducedAnd').text}</span>
                                <span onClick={() => openInNewTab('Uslovi_plaćanja_PAUSAL.pdf')} className="link">
                                    {t('pages.agency.subscription.cardPayment').text}
                                </span>
                            </div>
                        </div>
                    </Row>
                    {!isDevEnviroment ? (
                        <div className="recaptcha">
                            <ReCAPTCHA
                                sitekey="6LfMDIwpAAAAAEu8oskJGaSeDcqvcAqFRdXqDUGs"
                                onChange={handleRecaptchaChange}
                                hl={defaultLang}
                                className="g-recaptcha"
                            />
                        </div>
                    ) : null}

                    <div style={{ marginTop: '30px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="big"
                            size={200}
                            disabled={loader}
                            onClick={() => {
                                if (!isChecked) {
                                    setErrorMessageTerms(true);
                                    return;
                                } else if (isEmailValid || isFirstNameValid || isLastNameValid || isPibValid) {
                                    setValidationError(true);
                                    return;
                                } else if (!recaptchaToken && !isDevEnviroment) {
                                    setErrorMessageNotRobot(true);
                                    return;
                                } else {
                                    setLoader(true);
                                    setErrorMessageTerms(false);
                                    setErrorMessageNotRobot(false);
                                    initiateWsPay();
                                }
                            }}
                        >
                            {cart.total_price === 0 ? (
                                <p>{t('pages.agency.subscription.activate').text}</p>
                            ) : (
                                <p>{t('pages.agency.subscription.card_pay').text}</p>
                            )}
                        </Button>
                    </div>
                    {errorMessageTerms ? (
                        <p className="error">{t('pages.agency.subscription.error_terms').text}</p>
                    ) : null}
                    {errorMessageNotRobot ? (
                        <p className="error">{t('pages.agency.subscription.error_not_robot').text}</p>
                    ) : null}
                    <div>
                        <form name="pay" action={wspayFormUrl} method="POST">
                            <input type="hidden" name="ShopID" value="" />
                            <input type="hidden" name="ShoppingCartID" value="" />
                            <input type="hidden" name="Version" value="2.0" />
                            <input type="hidden" name="TotalAmount" value="" />
                            <input type="hidden" name="Signature" value="" />
                            {/* <input type="hidden" name="ReturnURL" value="http://localhost:3000/payment-success" />
                            <input type="hidden" name="CancelURL" value="http://localhost:3000/payment-cancel" />
                            <input type="hidden" name="ReturnErrorURL" value="http://localhost:3000/payment-error" /> */}
                            <input type="hidden" name="ReturnURL" value={`${returnUrl}/payment-success`} />
                            <input type="hidden" name="CancelURL" value={`${returnUrl}/payment-cancel`} />
                            <input type="hidden" name="ReturnErrorURL" value={`${returnUrl}/payment-error`} />
                            <input type="hidden" name="Lang" value={defaultLang} />
                            <input type="hidden" name="PaymentPlan" value="0000" />
                            <input type="hidden" name="CustomerFirstName" value={firstName} />
                            <input type="hidden" name="CustomerLastName" value={lastName} />
                            {/* <input type="hidden" name="CustomerEmail" value={email} /> */}
                            {/* <input type="hidden" name="CustomerAddress" value={`${agency.user.data.address}`} />
                            <input type="hidden" name="CustomerCity" value={agency.user.data.city} />
                            <input type="hidden" name="CustomerZIP" value="" />
                            <input type="hidden" name="CustomerCountry" value="SR" /> */}
                            {/* <input type="hidden" name="IsTokenRequest" value="1" /> */}
                            {/* <input type="hidden" name="CreditCardName" value="VISA" />
                            <input type="hidden" name="IntAmount" value="750,00" />
                            <input type="hidden" name="IntCurrency" value="RSD" /> */}
                            {/* <input type="submit" value="Pay" /> */}
                        </form>
                    </div>

                    <div className="paymentIcons">
                        {isMobile ? (
                            <>
                                <Cards>
                                    <img src={visaMobile} className="mobile" />
                                    <img src={masterMobile} className="mobile" />
                                    <img src={maestroMobile} className="mobile" />
                                    <img src={dinaMobile} className="mobile" />
                                </Cards>
                            </>
                        ) : (
                            <>
                                <Cards>
                                    <img src={visaLogo} />
                                    <img src={masterLogo} />
                                    <img src={maestroLogo} />
                                    <img src={dinaLogo} />
                                </Cards>
                            </>
                        )}
                        <WSpay>
                            <a
                                href="http://www.wspay.rs"
                                title="Monri WSpay - Web Secure Payment Gateway"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt="Monri WSpay - Web Secure Payment Gateway"
                                    src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png"
                                />
                            </a>
                            <a href="https://www.otpbanka.rs/" title="OTP banka" target="_blank" rel="noreferrer">
                                <img alt="OTP Banka" src={otpLogo} className="otp-logo" />
                            </a>
                        </WSpay>
                        <CardSecure>
                            <a
                                href="http://www.mastercard.com/rs/consumer/credit-cards.html"
                                title="Master Card ID Check"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={masterIdCheckLogo} />
                            </a>
                            <a
                                href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                                title="Visa Secure"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={visaSecureLogo} />
                            </a>
                        </CardSecure>
                    </div>
                </div>
            </ContentThirdStep>
            {isMobile && (
                <StepControlButton
                    step={'previous'}
                    handleClick={() => {
                        previousStep();
                        navigate('/guest/2');
                    }}
                />
            )}
        </>
    );
};
const ContentThirdStep = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 0;
    gap: 3rem;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        gap: 0;
    }
    .leftMain {
        width: 100%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        gap: 2rem;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
            max-width: 100%;
        }
    }
    .leftPage {
        width: 100%;
        background-color: var(--purple);
        padding: 20px;
        .inputFields {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            color: var(--white);
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                flex-direction: column;
            }
            .inputText {
                width: 100%;
                height: 50px;
                padding: 10px;
                margin-top: 5px;
                border: none;
            }
            label {
                display: block;
                width: 100%;
                text-align: left;
                font-size: 15px;
            }
        }
    }
    .rightPage {
        max-width: 50%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        color: var(--purple);
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
            max-width: 100%;
        }
        .title {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h2 {
                font-size: 20px;
                font-weight: bold;
                color: var(--purple);
                margin: 10px;
            }
        }
        .table {
            margin-top: 40px;
            width: 100%;
            padding: 20px 0;
            border-top: 1px solid var(--purple);
            border-bottom: 1px solid var(--purple);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            div {
                display: flex;
                justify-content: space-between;
                width: 100%;
                p {
                    font-size: 15px;
                }
            }
            button {
                border-radius: 0px;
            }
        }
        .total {
            margin-top: 0px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 20px 0;
            p {
                font-size: 15px;
            }
        }
        .paymentIcons {
            width: 90%;
            padding: 20px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
            }
        }
        .recaptcha {
            display: flex;
            justify-content: center;
            margin-left: 20px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                margin: 0;
                .g-recaptcha {
                    transform: scale(0.75);
                    // transform-origin: 0 0;
                }
            }
        }
        .widget {
            margin-top: 20px;
            background-color: red;
            width: 120%;
        }
    }
    input[type='radio'] {
        accent-color: #9b7fe9;
        height: 25px;
        width: 20px;
        margin-bottom: 10px;
    }
    .note {
        margin-top: 25px;
        font-size: 14px;
        color: black;
        text-align: center;
        line-height: 20px;
        padding: 5px;
        max-width: 520px;
    }
    .error {
        color: red;
        text-align: center;
        margin-top: 10px;
    }
    .error2 {
        color: red;
        margin-top: 5px;
        font-size: 14px;
        max-width: 200px;
    }
`;
const Title = styled.div`
    background-color: var(--purple);
    padding: 20px 10px;
    margin-top: 20px;
    h2 {
        font-size: 20px;
        color: var(--white);
        text-align: left;
    }
`;
const Cards = styled.div`
    width: 100%;
    text-align: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    .mobile {
        height: 23px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-wrap: wrap;
    }
`;
const CardSecure = styled.div`
    width: 100%;
    text-align: center;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 5px;
    .mobile {
        height: 23px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-wrap: wrap;
    }
`;
const WSpay = styled.div`
    display: flex;
    text-align: center;
    margin-top: 20px;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    .otp-logo {
        height: 50px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
`;
const Row = styled.div`
    margin-top: 10px;
    .checkbox {
        padding: 20px 0;
        display: flex;
        align-items: center;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 25px;
            color: var(--white);
            cursor: pointer;
        }
        .check-icon.checked {
            background-color: var(--purple);
            cursor: pointer;
        }
        .terms {
            margin-left: 10px;
            font-size: 15px;
            color: var(--purple);
            text-align: left;
        }
    }
    .link {
        cursor: pointer;
        text-decoration: underline;
        :hover {
            color: black;
        }
    }
`;
export default StepFour;
