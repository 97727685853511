import { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { faBars, faBorderAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useYearsStore from '../../../../store/years';
import SelectComponent, { SelectOption } from '../../../../components/select';
import Search from '../../../../components/input/search';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import {
    StyledBottomFilters,
    StyledDisplayIcons,
    StyledFiltersInner,
    StyledFiltersWrapper,
    StyledShowSelect,
    StyledStatusFilter,
    StyledTopFilters,
} from '../../common/styled-filters';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    searchValue?: string;

    setSearchValue: Dispatch<SetStateAction<string>>;
    setDisplay: Dispatch<SetStateAction<string>>;
    statusFilters?: SelectOption[];
    showLabel?: string;
}

const Filters: FunctionComponent<Props> = ({
    setParams,
    params,
    searchValue,
    setSearchValue,
    setDisplay,
    statusFilters,
    showLabel,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { years } = useYearsStore();
    const { isDesktop } = useResponsive();

    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [status, setStatus] = useState<Array<{ value: string | number; label: string }>>([]);
    const [showSearch, setShowSearch] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    useEffect(() => {
        const yearsData = years?.map((year) => {
            return { value: year, label: year };
        });

        setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);
    }, [currentLang]);

    useEffect(() => statusFilters && setStatus(statusFilters), [currentLang]);

    return (
        <StyledFiltersWrapper>
            <StyledTopFilters>
                <StyledFiltersInner>
                    {!isDesktop ? (
                        <SelectComponent
                            className="select-container"
                            optionList={status}
                            label={t('pages.invoices.filters.statusFilter').text}
                            handleSelectedValue={(value: string) => {
                                setParams({
                                    ...params,
                                    search: { ...params.search, 'eInvoice.status': value },
                                    page: 1,
                                    searchFields:
                                        value !== ''
                                            ? { ...params.searchFields, 'eInvoice.status': 'in' }
                                            : { ...params.searchFields, 'eInvoice.status': value },
                                });
                            }}
                            defaultSelectedOption={status[0]}
                        />
                    ) : (
                        <StyledStatusFilter className={`${selectedStatus ? '' : 'first-active'}`}>
                            <label>status</label>
                            {status.map((s) => {
                                return (
                                    <p
                                        className={`${
                                            selectedStatus === s?.value ? 'active' : ''
                                        } cursor-pointer`.trim()}
                                        key={nanoid()}
                                        onClick={() => {
                                            setSelectedStatus(s.value as any);
                                            setParams({
                                                ...params,
                                                search: { ...params.search, 'eInvoice.status': s.value },
                                                page: 1,
                                                searchFields:
                                                    s.value !== ''
                                                        ? { ...params.searchFields, 'eInvoice.status': 'in' }
                                                        : { ...params.searchFields, 'eInvoice.status': s.value },
                                            });
                                        }}
                                    >
                                        {s.label}
                                    </p>
                                );
                            })}
                        </StyledStatusFilter>
                    )}
                    <SelectComponent
                        className="select-container"
                        optionList={yearsOptions}
                        label={t('pages.invoices.filters.year.label').text}
                        handleSelectedValue={(value: string) => {
                            const parsedYear = parseInt(value);
                            const date = `${parsedYear}-01-01,${parsedYear}-12-31`;
                            setParams({
                                ...params,
                                page: 1,
                                search:
                                    value !== ''
                                        ? { ...params.search, invoice_date: date }
                                        : { ...params.search, invoice_date: value },
                                searchFields:
                                    value !== ''
                                        ? {
                                              ...params.searchFields,
                                              invoice_date: 'between',
                                          }
                                        : {
                                              ...params.searchFields,
                                              invoice_date: value,
                                          },
                            });
                        }}
                        defaultSelectedOption={status[0]}
                    />
                </StyledFiltersInner>
                <StyledDisplayIcons>
                    <FontAwesomeIcon
                        icon={faBars}
                        color={colors.white}
                        className="cursor-pointer"
                        onClick={() => setDisplay('list')}
                    />
                    <FontAwesomeIcon
                        icon={faBorderAll}
                        color={colors.white}
                        className="cursor-pointer"
                        onClick={() => setDisplay('grid')}
                    />
                </StyledDisplayIcons>
            </StyledTopFilters>
            <StyledBottomFilters>
                <StyledShowSelect>
                    <SelectComponent
                        optionList={showItems}
                        label={t('pages.invoices.filters.show.label').text}
                        handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                        defaultSelectedOption={showItems[0]}
                    />
                    <p>{showLabel}</p>
                </StyledShowSelect>
                <Search
                    placeholder={t('pages.invoices.filters.search').text}
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        setShowSearch(true);
                    }}
                    onLeaveOrEnter={(value: string) => {
                        setShowSearch(false);
                        setSearchValue(value);
                        setParams({
                            ...params,
                            search: { ...params.search, 'client.company_name': value },
                            page: 1,
                            searchFields:
                                value !== ''
                                    ? { ...params.searchFields, 'client.company_name': 'like' }
                                    : { ...params.searchFields, 'client.company_name': value },
                        });
                    }}
                    showSearchNote={showSearch}
                />
            </StyledBottomFilters>
        </StyledFiltersWrapper>
    );
};

export default Filters;
